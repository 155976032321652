import React from "react";
import locales from "../constants";
import { Layout, ContactFormDark } from "../components";
import { Link } from "gatsby";
import CountUp from "react-countup";
import { Waypoint } from "react-waypoint";

const ProwadzenieLinkedinPage = ({ location: { pathname } }) => {
  return (
    <Layout
      seo={{
        title: "Prowadzenie strony na Linkedin",
        description:
          "W naszym zespole odnajdziesz wyłącznie doświadczonych, zaangażowanych specjalistów Social Media.Jak nikt inny rozumiemy wagę słowa pisanego i potencjał Linkedin dla rozwoju Twojego biznesu. Docieraj do swoich klientów i stwórz spójny wizerunek marki na portalu Linkedin. ",
        href: pathname,
        lang: "pl",
      }}
    >
      <span className="anchor" id="home"></span>
      <div className="subpage-header subpage-header--single-offer">
        <h1 className="subpage-header__title">
          Prowadzenie strony <br /> <span>na LinkedIn</span>
        </h1>
      </div>

      <div className="single-offer-menu-container">
        <div className="row">
          <div className="col-lg-3">
            <Link to="/uslugi/#social-media">
              <div className="back-wrapper">
                <span className="back-wrapper__arrow"></span>
                <p className="back-wrapper__title">Usługi</p>
              </div>
            </Link>
            <p className="nav-title">Social Media:</p>
            <ul>
              <li>
                <Link to="/audyt-kanalow-social-media/">
                  Audyt kanałów social media
                </Link>
              </li>
              <li>
                <Link to="/strategia-komunikacji-social-media/">
                  Strategia komunikacji <br /> social media
                </Link>
              </li>
              <li>
                <Link to="/prowadzenie-strony-na-facebooku/">
                  Prowadzenie strony na Facebooku
                </Link>
              </li>
              <li>
                <Link to="/prowadzenie-konta-instagram/">
                  Prowadzenie konta Instagram
                </Link>
              </li>
              <li className="current">
                <a href="#home">Prowadzenie strony na LinkedIn</a>
              </li>
              <li>
                <Link to="/kampanie-facebook-ads/">Kampanie Facebook Ads</Link>
              </li>
              <li>
                <Link to="/kampanie-na-instagramie/">
                  Kampanie na Instagramie
                </Link>
              </li>
              <li>
                <Link to="/tiktok-ads/">Kampanie TikTok Ads</Link>
              </li>
              {/* <li>
                <Link to="/chatbot-w-komunikacji-marki/">
                  Chatbot w komunikacji marki
                </Link>
              </li> */}
            </ul>
          </div>
        </div>
      </div>

      <section className="single-offer-section-text-left single-offer-section-text-left--cat-3">
        <div className="row">
          <div className="col-xl-8 offset-xl-4 col-lg-9 offset-lg-3">
            <div className="row align-items-center">
              <div className="col-lg-6 text-col">
                <h2 className="text-col__title mb-40">
                  Biznes na <br /> wyciągnięcie ręki
                </h2>
                <p className="text-col__desc">
                  LinkedIn to międzynarodowy portal o charakterze
                  biznesowo-zawodowym, który gromadzi profesjonalistów z różnych
                  branż. Jeżeli grupą docelową Twojej firmy lub marki jest
                  określona branża lub skupia się ona na konkretnym stanowisku,
                  roli w hierarchii firmy czy wielkości firmy, LinkedIn będzie
                  idealnym wyborem. Za pomocą strony Linkedin możesz ażngażować
                  swoja grupę docelową, wchodzić z nią w interekcję i za pomocą
                  systematycznej, zaplanowanej komunikacji budować trwałą
                  relację z odbiorcami. <strong>LinkedIn</strong> to również
                  doskonałe narzędzie dla reklamodawców, które oferuje
                  niespotykane na innych portalach metody targetowania reklamy.
                </p>
              </div>
              <div className="col-lg-6 img-col">
                <img
                  className="img-fluid img-col__image"
                  src={require("../assets/images/linkedin-img-1.jpg")}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="single-offer-section-text-right">
        <div className="row">
          <div className="col-xl-9 offset-xl-3 col-lg-9 offset-lg-3">
            <div className="row align-items-center">
              <div className="col-lg-6 img-col">
                <img
                  className="img-fluid img-col__image"
                  src={require("../assets/images/facebook-img-2.jpg")}
                  alt=""
                />
              </div>
              <div className="col-lg-6 text-col">
                <h2 className="text-col__title mb-40">
                  Partnerzy <br /> w sukcesie
                </h2>
                <p className="text-col__desc">
                  W naszym zespole odnajdziesz wyłącznie doświadczonych,
                  zaangażowanych Specjalistów Social Media, którzy jak nikt inny
                  rozumieją wagę słowa pisanego i potencjał social media dla
                  rozwoju Twojego biznesu.
                  <br />
                  <br />
                  Odkryjesz u nas jednocześnie fascynację z prowadzonego
                  projektu, kreatywność i odwagę w proponowanych rozwiązaniach,
                  wiedzę na temat najnowszych rozwiązań w dziedzinie social
                  media, ale również twarde umiejętności analityczne. To właśnie
                  dzięki nim obiektywnie i wnikliwie podchodzimy do analizy
                  osiąganych efektów i realizacji wyznaczonych celów.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="single-offer-section-counter">
        <div className="row">
          <div className="col-xl-8 offset-xl-3 col-lg-9 offset-lg-3">
            <h2 className="single-offer-section-counter__text">
              Ponad
              <CountUp start={0} end={4000000} separator=" " duration={2}>
                {({ countUpRef, start }) => (
                  <div className="counter-wrapper">
                    <span ref={countUpRef} />
                    <Waypoint onEnter={start} />
                  </div>
                )}
              </CountUp>{" "}
              <br />
              aktywnych <br />
              użytkowników w Polsce
            </h2>
          </div>
        </div>
      </section>
      <section className="single-offer-section-big-img single-offer-section-big-img--linkedin">
        <div className="row">
          <div className="col-xl-7 col-lg-8 offset-lg-4">
            <div className="image-container">
              <img
                className="single-offer-section-big-img__image image image--desktop"
                src={require("../assets/images/graph-linkedin.png")}
                alt=""
              />
              <img
                className="single-offer-section-big-img__image image image--mobile"
                src={require("../assets/images/graph-linkedin-mobile.png")}
                alt=""
              />
              <p className="image-container__text text text--1">
                Najnowsze <br /> rozwiązania
              </p>
              <p className="image-container__text text text--2">
                Spójne <br /> strategie komunikacji
              </p>
              <p className="image-container__text text text--3">
                Precyzyjnie <br /> dobrana grupa <br /> docelowa
              </p>
              <p className="image-container__text text text--4">
                Partnerska współpraca
              </p>
              <p className="image-container__text text text--5">
                Tylko doświadczeni <br /> specjaliści <br /> Social Media
              </p>
              <p className="image-container__text text text--6">
                Najnowsze <br /> formaty <br /> i funkcjonalności
              </p>
              <p className="image-container__text text text--7">
                Niebanalne <br /> pomysły
              </p>
              <ul className="big-bullets list-mobile">
                <li>Najnowsze rozwiązania</li>
                <li>Spójne strategie komunikacji</li>
                <li>Precyzyjnie dobrana grupa decelowa</li>
                <li>Partnerska współpraca</li>
                <li>Tylko doświadczeni specjaliści Social Media</li>
                <li>Najnowsze formaty i funkcjonalności</li>
                <li>Niebanalne pomysły</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="single-offer-section-timeline single-offer-section-timeline--instagram">
        <div className="row">
          <div className="col-xl-7 col-lg-8 offset-lg-4">
            <div className="header-container">
              <div className="custom-row">
                <div className="left-col">
                  <img
                    src={require("../assets/images/ico-arrow-timeline.svg")}
                    alt=""
                    className="header-container__arrow"
                  />
                </div>
                <div className="right-col">
                  <h3 className="header-container__title">
                    Zobacz ścieżkę naszego wspólnego sukcesu
                  </h3>
                </div>
              </div>
            </div>
            <div className="timeline-container">
              <span className="timeline-container__line"></span>
              <div className="single-item">
                <div className="custom-row">
                  <div className="left-col">
                    <p className="single-item__number">1</p>
                  </div>
                  <div className="right-col">
                    <p className="single-item__title">Analiza</p>
                    <p className="single-item__desc">
                      Przeanalizujemy dotychczasowe działania Twojej marki oraz
                      konkurencji na portalu LinkedIn.
                    </p>
                  </div>
                </div>
              </div>
              <div className="single-item">
                <div className="custom-row">
                  <div className="left-col">
                    <p className="single-item__number">2</p>
                  </div>
                  <div className="right-col">
                    <p className="single-item__title">Strategia komunikacji</p>
                    <p className="single-item__desc">
                      Na podstawie zebranych danych i materiałów stworzymy
                      propozycję komunikacji, szaty graficznej, linii postów,
                      które będą dopasowane do konkretnych grup odbiorców.
                    </p>
                  </div>
                </div>
              </div>
              <div className="single-item">
                <div className="custom-row">
                  <div className="left-col">
                    <p className="single-item__number">3</p>
                  </div>
                  <div className="right-col">
                    <p className="single-item__title">Harmonogram postów</p>
                    <p className="single-item__desc">
                      Przed rozpoczęciem każdego kolejnego miesiąca otrzymasz od
                      nas harmonogram postów na dany miesiąc. Zajmiemy się
                      tworzeniem grafik, treści, publikacją postów i moderacją
                      komentarzy, dzięki czemu Ty możesz zająć się tym, co dla
                      Ciebie najważniejsze.
                    </p>
                  </div>
                </div>
              </div>
              <div className="single-item">
                <div className="custom-row">
                  <div className="left-col">
                    <p className="single-item__number">4</p>
                  </div>
                  <div className="right-col">
                    <p className="single-item__title">
                      Rozwijanie społeczności
                    </p>
                    <p className="single-item__desc">
                      Prowadzone działania będą przez są wspierane precyzyjnie
                      zaplanowaną reklamą LinkedIn Ads. Docieraj do swoich
                      klientów dzięki reklamie targetowanej po konkretnym
                      stanowisku, roli w hierarchii firmy, branży, wielkości
                      firmy a nawet do pracowników wybranych firm.
                    </p>
                  </div>
                </div>
              </div>
              <div className="single-item">
                <div className="custom-row">
                  <div className="left-col">
                    <p className="single-item__number">5</p>
                  </div>
                  <div className="right-col">
                    <p className="single-item__title">Raport i rekomendacje</p>
                    <p className="single-item__desc">
                      Nie spoczywamy na laurach. Każdy miesiąc kończymy nie
                      tylko pełnym raportem z przeprowadzonych działań i efektów
                      kampanii, ale przede wszystkim dalszymi rekomendacjami w
                      kierunku maksymalizacji potencjału Twojej marki w tym
                      kanale.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="single-offer-section-testimonials">
        <div className="row">
          <div className="col-xl-7 offset-xl-4 col-lg-9 offset-lg-3">
            <h2 className="single-offer-section-testimonials__title">
              Zobacz, co myślą o nas nasi klienci
            </h2>
            <div className="testimonials-container">
              <div className="single-testimonial">
                <span className="single-testimonial__pseudo pseudo pseudo--circle-full"></span>
                <p className="single-testimonial__text">
                  When, to przede wszystkim bardzo rzetelny i pomocny zespół po
                  prostu fajnych i uczciwych ludzi. Mój projekt w końcu jest w
                  bezpiecznych i profesjonalnych rękach. Jestem przekonana, iż
                  przy takim zaangażowaniu i takiej współpracy osiągniemy
                  wyznaczone cele. Po stokroć polecam! <br />
                  <strong>Aldona Sosnowska</strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="single-offer-section-clients">
        <div className="row">
          <div className="col-xl-8 offset-xl-3 col-lg-9 offset-lg-3">
            <div className="section-header aligncenter">
              <span className="section-header__small-text">
                Wiemy, jak osiągnąć sukces dzięki Google Ads
              </span>
              <h2 className="section-header__title">Zaufali nam</h2>
            </div>
            <div className="row align-items-center justify-content-center">
              <div className="col-md-3 col-sm-4 col-6 client-col">
                <div className="client-col__logo-wrapper">
                  <img
                    className="img-fluid client-col__logo"
                    src={require("../assets/img/clients/thailand.png")}
                    alt=""
                  />
                </div>
              </div>
              <div className="col-md-3 col-sm-4 col-6 client-col">
                <div className="client-col__logo-wrapper">
                  <img
                    className="img-fluid client-col__logo"
                    src={require("../assets/img/clients/gmr.jpg")}
                    alt=""
                  />
                </div>
              </div>
              <div className="col-md-3 col-sm-4 col-6 client-col">
                <div className="client-col__logo-wrapper">
                  <img
                    className="img-fluid client-col__logo"
                    src={require("../assets/img/clients/komputronik.png")}
                    alt=""
                  />
                </div>
              </div>
              <div className="col-md-3 col-sm-4 col-6 client-col">
                <div className="client-col__logo-wrapper">
                  <img
                    className="img-fluid client-col__logo"
                    src={require("../assets/img/clients/krakow.png")}
                    alt=""
                  />
                </div>
              </div>
              <div className="col-md-3 col-sm-4 col-6 client-col">
                <div className="client-col__logo-wrapper">
                  <img
                    className="img-fluid client-col__logo"
                    src={require("../assets/img/clients/techland.png")}
                    alt=""
                  />
                </div>
              </div>
              <div className="col-md-3 col-sm-4 col-6 client-col">
                <div className="client-col__logo-wrapper">
                  <img
                    className="img-fluid client-col__logo"
                    src={require("../assets/img/clients/greenpoint.png")}
                    alt=""
                  />
                </div>
              </div>
              <div className="col-md-3 col-sm-4 col-6 client-col">
                <div className="client-col__logo-wrapper">
                  <img
                    className="img-fluid client-col__logo"
                    src={require("../assets/img/clients/steelseries.png")}
                    alt=""
                  />
                </div>
              </div>
              <div className="col-md-3 col-sm-4 col-6 client-col">
                <div className="client-col__logo-wrapper">
                  <img
                    className="img-fluid client-col__logo"
                    src={require("../assets/img/clients/imperial.png")}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <span className="anchor" id="kontakt"></span>
      <section className="single-offer-section-contact">
        <div className="form-wrapper">
          <h2 className="single-offer-section-contact__title mb-40">
            Skontaktuj się z nami! <br />
            Wypełnij <span>formularz</span>
          </h2>
          <ContactFormDark locales={locales["pl"]} />
        </div>
        <img
          className="img-fluid single-offer-section-contact__hand"
          src={require("../assets/images/home-contact-hand-right.png")}
          alt=""
        />
      </section>
    </Layout>
  );
};
export default ProwadzenieLinkedinPage;
